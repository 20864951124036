<template>
  <div class="text-h1 text-center text-secondary q-pa-xl">
    Player application
  </div>
  <div v-if="closed">
    <div class="text-h1 text-center text-secondary">
      CURRENTLY CLOSED
    </div>
    <div class="text-justify q-pa-md content">
      At this time we are not accepting any applications! Please visit our
      discord for more information on when applications will be re-opened!
      Typically if we have to close appilications we are either at full capacity
      with extensive queue times or our staff simply cannot keep up with the
      sheer volume of applications and we are temporarily closing them until we
      can catch up!
    </div>
  </div>
  <div v-if="!closed">
    <div class="text-justify q-pa-md content">
      <p>
        Thank you for applying to Red Skies Roleplay! We are thrilled that you
        want to join us in telling this story and bringing your character to
        life! Once you fill out this application it will be reviewed by our
        staff and you will either receive a message accepting or declining your
        application.
      </p>
      <p>
        If you are accepted you will then be scheduled for a voice interview
        where you will get to share some information about yourself as well as
        demonstrate your ability to improv and roleplay. Upon review of the
        voice interview we will again let you know if you have been accepted or
        declined.
      </p>
      <div class="q-pa-md">
        <span class="question">Please Enter your Discord ID</span>
        <q-input
          v-model="app.Discord"
          hint="eg. username#0001"
          :disable="closed"
        />
      </div>
      <div class="q-pa-md">
        <span class="question"
          >Please Enter your Application Code. If you dont have this yet please
          try connecting to our RedM server and it will give you one!</span
        >
        <q-input v-model="app.AppCode" hint="eg. A0C2" :disable="closed" />
      </div>

      <!-- O LORD! -->
      <div v-for="(question, index) in app.Questions" :key="index">
        <div
          v-if="question.Type === 'section'"
          class="text-h1 text-secondary q-pa-md"
        >
          {{ question.Question }}
        </div>
        <div v-else class="question q-pa-md">
          {{ question.Question }}

          <div v-if="question.Type === 'short'">
            <q-input v-model="question.Answer" />
          </div>
          <div v-else-if="question.Type === 'long'">
            <q-input v-model="question.Answer" filled type="textarea" />
          </div>
          <div v-else-if="question.Type === 'multi'">
            <q-radio
              v-for="(opt, index) in question.Options"
              :key="index"
              :val="opt"
              :label="opt"
              color="secondary"
              v-model="question.Answer"
            />
          </div>
          <div v-else-if="question.Type === 'select'">
            <q-select
              dense
              color="secondary"
              options-dense
              v-model="question.Answer"
              :options="question.Options"
              filled
            />
          </div>
        </div>
      </div>
      <!-- WE DONE! -->

      <q-btn
        class="full-width"
        label="Submit Application"
        color="secondary"
        size="xl"
        @click="sendMessage"
      />
    </div>
  </div>
  <q-dialog v-model="alert">
    <q-card>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ alertMessage }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="secondary" size="xl" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
//const hook = "https://discord.com/api/webhooks/863894678180593674/9m-vXXUk_zWr_QEvJ_FtJ8zrCVANiBzzV6Y2wopVLFFE_4_0vXR9aItqXGiCAcnvUk6j";
var api;
if (process.env.NODE_ENV == "production") {
  api = "http://api.redskiesrp.com";
} else {
  api = "http://127.0.0.1:3001";
}
//import { AppQuestion } from "../components/AppQuestion.vue";
export default {
  //components: [AppQuestion],
  data() {
    return {
      alertMessage: "Unknown",
      alert: false,
      closed: false,
      app: {
        AppCode: null,
        Discord: null,
        Questions: [
          {
            Type: "section",
            Question: "GENERAL QUESTIONS",
            Answer: null,
          },
          {
            Type: "short",
            Question: "Where did you learn about Red Skies Role Play?",
            Answer: null,
          },
          {
            Type: "multi",
            Options: ["Yes", "No"],
            Question: "Do you have a working Microphone?",
            Answer: null,
          },
          {
            Type: "multi",
            Options: ["1-5", "5-10", "10-20", "20+"],
            Question: "How many hours a week do typically play?",
            Answer: null,
          },
          {
            Type: "select",
            Options: [
              "HST: Hawaii GMT-10",
              "AST: Alaska GMT-9",
              "PST: Pacific GMT-8",
              "PNT: Phoenix GMT-7",
              "MST: Mountain GMT-7",
              "CST: Central GMT-6",
              "EST: Eastern GMT-5",
              "PRT: Puerto Rico GMT-4",
              "CNT: Canada Newfoundland GMT-3:30",
              "CAT: Central African GMT-1",
              "GMT: Greenwich Mean Time +0",
              "ECT: European Central GMT+1",
              "EET: Eastern European GMT+2",
              "ART: Egypt GMT+2",
              "EAT: Eastern African GMT+3",
              "MET: Middle Eastern GMT+3:30",
              "NET: Near Eastern GTM+4",
              "PLT: Pakistan GMT+5",
              "IST: India GMT+5:30",
              "BST: Bangladesh GMT+6",
              "VST: Vietnam GMT+7",
              "CTT: China Taiwan GMT+8",
              "JST: Japan GMT+9",
              "ACT: Australia Central GMT+9:30",
              "AET: Australia Eastern GMT+10",
              "SST: Solomon GMT+11",
              "NST: New Zealand GMT+12",
              "MIT: Midway GMT-11",
              "HST: Hawaii GMT-10",
            ],
            Question: "What timezone do you play from?",
            Answer: null,
          },
          {
            Type: "section",
            Question: "SERVER RULES",
          },
          {
            Type: "multi",
            Options: ["Yes", "No"],
            Link: "/rules",
            Question: "Have you read and agree with the rules of the server?",
            Answer: null,
          },
          {
            Type: "long",
            Question:
              "Please briefly describe RDM and why it is a problem for RP.",
            Link: "/rules#rdm",
            Answer: null,
          },
          {
            Type: "long",
            Question:
              "What is your opinion on META information and how it can affect role play?",
            Link: "/rules#meta",
            Answer: null,
          },
          {
            Type: "long",
            Question:
              "In what ways can a character become permanently dead in Red Skies?",
            Link: "/rules#perma",
            Answer: null,
          },
          {
            Type: "section",
            Question: "ROLEPLAY",
          },
          {
            Type: "long",
            Question:
              "Tell us about your previous experience with role-playing including any video game RP, table top, live action or even theater!",
            Answer: null,
          },
          {
            Type: "long",
            Question: "What do you enjoy most about Role Playing?",
            Answer: null,
          },
          {
            Type: "multi",
            Options: ["Yes", "No"],
            Question: "Have you read the History and Lore of Dorado?",
            Link: "/history",
            Answer: null,
          },
          {
            Type: "short",
            Question:
              "What treaty ensured native americans would not lose anymore land in Dorado in exchange for help with the resistance?",
            Link: "/history#bearcave",
            Answer: null,
          },
          {
            Type: "short",
            Question: "What was the cause of the Widows Rebelion",
            Link: "/history#widowsrebelion",
            Answer: null,
          },
          {
            Type: "short",
            Question: "What year was the Treaty of Blackwater signed?",
            Link: "/history#blackwater",
            Answer: null,
          },
          {
            Type: "long",
            Question:
              "If you would like to share any links or videos of your previous roleplay or and audition piece, please add them here with a breif description.",
            Answer: null,
          },
          {
            Type: "section",
            Question: "CHARACTER",
          },
          {
            Type: "long",
            Question:
              "What is a potential character you are considering playing on Red Skies RP?  Please provide a name and short bio (This character does not need to be the one you end up playing)",
            Answer: null,
          },
          {
            Type: "short",
            Question: "What are they good at?",
            Answer: null,
          },
          {
            Type: "short",
            Question: "What are their character flaws?",
            Answer: null,
          },
          {
            Type: "short",
            Question: "What are their goals?",
            Answer: null,
          },
        ],
      },
    };
  },
  methods: {
    async sendMessage() {
      let res = await this.axios.post(api + "/application/submit", this.app);

      let msg = "No Response Something Is Wrong!";
      console.log(res);
      switch (res.data) {
        case "OK":
          msg =
            "Application submitted sucessfully!  Please visit our Discord and wait for further instructions!";
          break;
        case "ALREADY_SUBMITED":
          msg =
            "You have already submitted your application! Check on our Discord for more information!";
          break;
        case "MISSING_APP":
          msg = "Please enter a valid Application code!";
          break;
        case "MISSING_DISCORD":
          msg = "Please enter a valid discord identity!";
          break;
        case "INVALID_KEY":
          msg =
            "The application key was invalid, please ensure you are entering it correctly!";
          break;
        case "INVALID_DISCORD":
          msg = "The Discord Id was invalid, please check for typos!";
          break;
        case "KEY_NOT_FOUND":
          msg =
            "The application key you entered cannot be found. Re-check your entry or connect to the game server to get a key if you don't have one.";
          break;
        case "DISCORD_NOT_FOUND":
          msg =
            "We could not find your discord ID on our server! Please be sure to joing using the Discord link at the top of the page.";
          break;
      }
      this.alertMessage = msg;
      this.alert = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.question
  color: #AAA !important

.submit
  margin: 0 auto
</style>
