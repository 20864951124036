import { render } from "./Apply.vue?vue&type=template&id=7d337fd8&scoped=true"
import script from "./Apply.vue?vue&type=script&lang=js"
export * from "./Apply.vue?vue&type=script&lang=js"

import "./Apply.vue?vue&type=style&index=0&id=7d337fd8&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-7d337fd8"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QRadio,QSelect,QBtn,QDialog,QCard,QCardSection,QCardActions});qInstall(script, 'directives', {ClosePopup});
